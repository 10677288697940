<template>
  <div class="pbf grid_width reviews_boxs">



    <div class="sortb">
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="sortOptions"
        />
    </div>

 


      <SkBasicRowsBold 
        v-if="is_loading && start == 0"
        />

      <template v-else v-for="pro in review_data.items"> 
        <GeneralReview
          :id="pro.id" 
          :strain-comment="pro.text" 
          :comment="pro.comment" 
          :strain-general="pro?.n_general" 
          :post_id="pro.post_id" 
          :add-date="pro.add_date" 
          :strain-resistance="pro?.props?.resistance" 
          :strain-growing="pro?.props?.growing" 
          :item-diary="pro.item_diary" 
          :item-product="pro.item_product" 
          :item-user="pro.item_user"
          :items-product="pro.items_product"
        />
      </template> 

      <ClientOnly>        
        <UiInfinityScroll 
          v-if="!isEnded"
          :is-loading="is_loading"
          ref="elInfinityScroll"/>
      </ClientOnly>


  </div>
</template>

<script setup lang="ts">

import { getTagsKeyword, getTagsKeys, getTagsSimple  } from '@/types/other'

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const isEnded = ref(false)
const {$api, $ga} = useNuxtApp()
const elInfinityScroll = ref(null)

const props = defineProps({
  inurl: {
    type: String,
    required: true
  },
  intags: {
    type: Object,
    required: false
  }, 
  brandId: {
    type: Number,
    default: null
  },
  productId: {
    type: Number,
    default: null
  },
  growerId: {
    type: Number,
    default: null
  },
  strainId: {
    type: Number,
    required: false
  }
})

const start = ref(0);
const limit = ref(20);
const query = ref('');
const sort = ref('create_desc');
const sortOptions = ref([
  {id:'rate_desc', tr: 'universal_sort_review', icon: 'desc'},    
  {id:'rate_asc', tr: 'universal_sort_review', icon: 'asc'},    
  {id:'create_desc', tr: 'universal_sort_creation_date', icon: 'desc'},
  {id:'create_asc', tr: 'universal_sort_creation_date', icon: 'asc'},
  {id:'az_desc', tr: 'universal_sort_az', icon: 'desc'},
  {id:'az_asc', tr: 'universal_sort_az', icon: 'asc'},
  // {id:'gwatt_desc', tr: 'universal_sort_gwatt', icon: 'desc'},
  // {id:'gwatt_asc', tr: 'universal_sort_gwatt', icon: 'asc'},
  // {id:'gplant_desc', tr: 'universal_sort_gplant', icon: 'desc'},
  // {id:'gplant_asc', tr: 'universal_sort_gplant', icon: 'asc'},
]);
const tagscount = ref([]);
const tagsselected = ref([]);
const tagspermanent = ref([]);
const tagsdefault = ref(null);
const list = ref([]);



if(props.strainId){
  tagspermanent.value.push('st:' + props.strainId);
}

if(props.growerId){
  tagspermanent.value.push('us:' + props.growerId);
}

if(props.brandId){
  tagspermanent.value.push('br:' + props.brandId);
}

if(props.productId){
  tagspermanent.value.push('pr:' + props.productId);
}



 
const clearPagination = function(){     
  start.value = 0;  
}


const unchooseTags = function(selected){  
  tagsselected.value = tagsselected.value.filter((item) => item != selected);
}        
const chooseTags = function(selected){          
  tagsselected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', getTagsSimple(props.intags, tagsselected.value, tagspermanent.value));
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}

 



const loadData = async function() {  
  const response = await $api.getUrl(props.inurl, {
    start: start.value,
    limit: limit.value,
    q: getTagsKeyword(props.intags, tagsselected.value, tagspermanent.value),       
    sortable: sort.value,
    tagsall: getTagsKeys(props.intags),
    tags: getTagsSimple(props.intags, tagsselected.value, tagspermanent.value)
  });
  return response;  
}



const { pending: is_loading,  data: review_data } = await useLazyAsyncData('review_data', async () => await loadData())


watchArray([start, sort, tagsselected], async ([new_start, new_sort, new_tagsselected], added, removed) => {
  
  if(new_start == 0){
    review_data.value.items = [];
    isEnded.value = false;
  }
  
  console.log('watcher');
  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.items?.length == 0)
    isEnded.value = true;  

  review_data.value.items = [...review_data.value.items, ...dt.items]
  // review_data.value.items_tag = [...review_data.value.items_tag, ...dt.items_tag]
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

</script>

<style scoped>

.sortb{
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
}
.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}

</style>
